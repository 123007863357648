import React, { Component } from "react";
import { Button, Dialog } from "react-weui";
import { Redirect } from "react-router-dom";

import { API, DeviceName, PlatformId } from "../proxy/BaseHttp";
import { DB } from "../proxy/BaseModal";

var _selectDeviceItem = null;

class Home extends Component {
  state = {
    list: [],
    isOk: false,
    wifiReady: false,
    toastShow: false,
    toastMessage: "",
    toastStyle: {
      buttons: [
        {
          label: "确认",
          onClick: this.hideDialog.bind(this),
        },
      ],
    },

    user: {},
  };
  componentDidMount() {
    this.getConfig();

    var user = DB.USERINFO({ type: "GET" });
    if (user) this.setState({ user: user["user"] });
  }
  async getConfig() {
    try {
      // var res = await API.getSignJsapi("http://wechat-connect.iot-expeed.com");
      var res = await API.getSignJsapi(window.location.href.split("#")[0]);
      window.wx.config({
        beta: true,
        debug: false,
        appId: res.data.appId,
        timestamp: res.data.timestamp,
        nonceStr: res.data.nonceStr,
        signature: res.data.signature,
        jsApiList: [
          "startScanWXDevice",
          "openWXDeviceLib",
          "configWXDeviceWiFi",
          "onScanWXDeviceResult",
          "closeWXDeviceLib",
          "stopScanWXDevice",
        ],
      });

      setTimeout(() => {
        window.wx.checkJsApi({
          jsApiList: [
            "openWXDeviceLib",
            "startScanWXDevice",
            "configWXDeviceWiFi",
            "onScanWXDeviceResult",
            "closeWXDeviceLib",
            "stopScanWXDevice",
          ],
          success: (res) => {},
        });
      }, 1000);
    } catch (error) {
      window.wx.showModal({
        content: "配置接口失败，请退出页面重试",
        showCancel: false,
        success: (res) => {},
      });
    }
  }
  clickItem(item, index) {
    if (_selectDeviceItem === item.deviceId) {
      return;
    }

    this.bindUser(
      DB.OPENID({ type: "GET" }),
      item.deviceId,
      DeviceName + item.deviceId
    );
  }
  hideDialog() {
    this.setState({ toastShow: false, toastMessage: "" });
  }
  showDialog(message) {
    this.setState({ toastShow: true, toastMessage: message });
  }
  async bindUser(openId, deviceId, nickName) {
    try {
      _selectDeviceItem = deviceId;
      var res = await API.bindUser(openId, deviceId, nickName);
      _selectDeviceItem = null;
      if (res.result_code === 0) {
        this.setState({ isOk: true });
      } else {
        this.showDialog(res.result_msg || "绑定失败");
      }
    } catch (e) {
      _selectDeviceItem = null;
      this.showDialog("绑定失败, 请重试");
    }
  }
  loading() {
    this.setState({ list: [] });
    window.wx.invoke("stopScanWXDevice", { connType: "lan" }, (res) => {
      if (res.err_msg === "stopScanWXDevice:ok") {
        window.wx.invoke("startScanWXDevice", { connType: "lan" }, (res2) => {
          if (res2.err_msg === "startScanWXDevice:ok") {
            this.scanResult();
          }
        });
      }
    });
  }
  startLink() {
    const { wifiReady } = this.state;
    if (!wifiReady) {
      window.wx.invoke("configWXDeviceWiFi", {}, (res) => {
        var err_msg = res.err_msg;
        if (err_msg === "configWXDeviceWiFi:ok") {
          this.setState({ wifiReady: true, list: [] });
          this.startSearch();
        }
        //  else {
        //   this.showDialog(JSON.stringify(res));
        // }
      });
    }
  }
  startSearch() {
    window.wx.invoke("openWXDeviceLib", { connType: "lan" }, (res) => {
      if (res.err_msg === "openWXDeviceLib:ok") {
        window.wx.invoke("startScanWXDevice", { connType: "lan" }, (res2) => {
          if (res2.err_msg === "startScanWXDevice:ok") {
            this.scanResult();
          }
        });
      } else {
        setTimeout(() => {
          this.startSearch();
        }, 3000);
      }
    });
  }
  scanResult() {
    window.wx.on("onScanWXDeviceResult", (res3) => {
      if (res3 && res3["devices"]) {
        if (PlatformId === "newcon") {
          alert(JSON.stringify(res3["devices"]));
        }
        this.setState({ list: res3["devices"] });
      }
    });
  }
  render() {
    const { list, isOk, wifiReady, toastStyle, toastShow, toastMessage, user } = 
      this.state;
    if (isOk) return <Redirect to={{ pathname: "/res", state: {} }} />;
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            fontSize: 12,
            color: "rgba(0,0,0,0.2)",
          }}
        >
          {"当前手机号: " + (user && user["phoneNum"])}
        </div>
        <div
          style={{
            margin: "50px 0 40px 0 ",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={{ width: 120 }} src="../assets/wifi.png" alt="wifi" />
          <div>点击按钮进行联网</div>
        </div>
        <div style={{ height: 10 }} />
        <div style={{ width: "90%", margin: "auto" }}>
          <Button onClick={() => this.startLink()} disabled={wifiReady}>
            开始配网
          </Button>
        </div>
        <div style={{ flex: 1, margin: "20px 0" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              margin: "10px 5%",
            }}
          >
            <img
              style={{ height: 25, marginRight: 5 }}
              src="../assets/link.png"
              alt="link"
            />
            <div>已配网设备</div>
            <img
              onClick={() => this.loading()}
              style={{ height: 25, position: "absolute", right: "5%" }}
              src="../assets/loading.png"
              alt="loading"
            />
          </div>
          <div>
            {list.length > 0 ? (
              list.map((item, index) => {
                return this.renderItem(item, index);
              })
            ) : (
              <div style={{ margin: 20, color: "rgba(0,0,0,0.4)" }}>
                {wifiReady ? "正在搜索设备..." : "请先配网"}
              </div>
            )}
            {/* <div>{text}</div> */}
          </div>
        </div>
        <Dialog
          type="ios"
          // title={toastMessage}
          buttons={toastStyle.buttons}
          show={toastShow}
        >
          {toastMessage}
        </Dialog>
      </div>
    );
  }
  renderItem(item, index) {
    if (item === "waiting for deviceid") return null;
    return (
      <div
        onClick={() => this.clickItem(item, index)}
        style={{
          paddingLeft: 20,
          height: 50,
          borderBottomWidth: 1,
          borderBottomColor: "rgba(0,0,0,0.3)",
          borderBottomStyle: "solid",
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <div>设备识别码 {item.deviceId}</div>
      </div>
    );
  }
}
export default Home;
