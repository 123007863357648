import React, { Component } from "react";
import { Icon } from "react-weui/build/packages";

import { API } from "../proxy/BaseHttp";
import { DB } from "../proxy/BaseModal";

class ResultMsg extends Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          flexDirection: "column"
        }}
      >
        <IconBox
          icon={<Icon size="large" value="success" />}
          title="绑定成功!"
        />
        <div style={{height: "100px"}}></div>
      </div>
    );
  }
}
export default ResultMsg;

const IconBox = props => (
  <div
    className="icon-box"
    style={{
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    }}
  >
    {props.icon}
    <div className="icon-box__ctn">
      <h3 className="icon-box__title" style={{ margin: 10 }}>
        {props.title}
      </h3>
    </div>
  </div>
);
