var db = {
  OPENID:
    process.env.NODE_ENV === "development"
      ? "oj61j5gNel8kAWC6imFlvQyyvBIM"
      : "",
  USERINFO: {}
};

export const DB = {
  OPENID(action) {
    return this.SearchFunc(
      action,
      value => {
        db.OPENID = action.value;
      },
      () => {
        return db.OPENID;
      }
    );
  },
  USERINFO(action) {
    return this.SearchFunc(
      action,
      value => {
        db.USERINFO = action.value;
      },
      () => {
        return db.USERINFO;
      }
    );
  },
  SearchFunc(action, input, output) {
    switch (action.type) {
      case "SET":
        input(action.value);
        break;
      case "GET":
        return output();
      default:
        return undefined;
    }
  }
};

window.DB = DB;
