import { DB } from "./BaseModal";

const URL = "https://wechat-connect.expeedos.cn/api";
// const URL = "https://wechat-connect.iot-expeed.com/api";
export const DeviceName = "";
export var PlatformId = "sharndy"; //毛巾架
// export var PlatformId = "newcon"; //纽康捕蚊仪

export const API = {
  setPid(value) {
    PlatformId = value;
  },
  creatBody(object) {
    let formData = new FormData();
    for (var i in object) formData.append(i, object[i]);
    return formData;
  },
  sendSMS(phone) {
    return fetch(`${URL}/util/sendSMS`, {
      method: "POST",
      body: this.creatBody({ phone: phone }),
    })
      .then((Response) => Response.json())
      .then((ResponseJson) => {
        return ResponseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  checkSMS(phone, code) {
    return fetch(`${URL}/util/checkSMS?phone=${phone}&code=${code}`, {
      method: "GET",
    })
      .then((Response) => Response.json())
      .then((ResponseJson) => {
        return ResponseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  getOpenId(code, type) {
    return fetch(`${URL}/util/openId`, {
      method: "POST",
      body: this.creatBody({ code: code, type: type, platformId: PlatformId }),
    })
      .then((Response) => Response.json())
      .then((ResponseJson) => {
        if (ResponseJson.data)
          DB.OPENID({ type: "SET", value: ResponseJson.data });
        return ResponseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  getSignJsapi(url) {
    return fetch(`${URL}/util/signJsapi`, {
      method: "POST",
      body: this.creatBody({ url: url, platformId: PlatformId }),
    })
      .then((Response) => Response.json())
      .then((ResponseJson) => {
        return ResponseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  getUserCheck(openId) {
    return fetch(`${URL}/user/check?openId=${openId}`, {
      method: "GET",
    })
      .then((Response) => Response.json())
      .then((ResponseJson) => {
        return ResponseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  getUserBind(username, openId) {
    return fetch(`${URL}/user/bind`, {
      method: "POST",
      body: this.creatBody({
        username: username,
        platformId: PlatformId,
        openId: openId,
      }),
    })
      .then((Response) => Response.json())
      .then((ResponseJson) => {
        return ResponseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  getUserUnBind(username, openId) {
    return fetch(`${URL}/user/unbind`, {
      method: "POST",
      body: this.creatBody({ username: username, openId: openId }),
    })
      .then((Response) => Response.json())
      .then((ResponseJson) => {
        return ResponseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  bindUser(openId, deviceId, deviceNickname) {
    return fetch(`${URL}/device/bindUser`, {
      headers: {
        token: openId,
      },
      method: "POST",
      body: this.creatBody({
        deviceId: deviceId,
        deviceNickname: deviceNickname,
      }),
    })
      .then((Response) => Response.json())
      .then((ResponseJson) => {
        return ResponseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  getDeviceList(openId, pageNum, pageSize) {
    return fetch(`${URL}/device/list`, {
      headers: {
        token: openId,
      },
      method: "POST",
      body: this.creatBody({ pageNum: pageNum, pageSize: pageSize }),
    })
      .then((Response) => Response.json())
      .then((ResponseJson) => {
        return ResponseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  unBindUser(openId, deviceId) {
    return fetch(`${URL}/device/unbindUser`, {
      headers: {
        token: openId,
      },
      method: "POST",
      body: this.creatBody({ deviceId: deviceId }),
    })
      .then((Response) => Response.json())
      .then((ResponseJson) => {
        return ResponseJson;
      })
      .catch((error) => {
        return error;
      });
  },
  getDeviceInfo() {},
  commonDevice() {},
  getDeviceHistory() {},
};
