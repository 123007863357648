import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import NoPage from "../template/NoPage";
import Competence from "../template/Competence";

import Home from "../template/Home";
import Login from "../template/Login";
import ResultMsg from "../template/ResultMsg";

class RootRouter extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Competence} />
          <Route path="/index" component={Competence} />
          <Route path="/home" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/res" component={ResultMsg} />
          <Route component={NoPage} />
        </Switch>
      </Router>
    );
  }
}
export default RootRouter;
