import React, { Component } from "react";
import {
  Form,
  FormCell,
  CellBody,
  CellHeader,
  CellFooter,
  Input,
  Label,
  Button,
  Dialog,
} from "react-weui/build/packages";
import { Redirect } from "react-router-dom";

import { API } from "../proxy/BaseHttp";
import { DB } from "../proxy/BaseModal";

class Login extends Component {
  state = {
    isOk: false,
    phone: "",
    code: "",
    isSendCode: false,
    codeNum: 60,
    toastShow: false,
    toastMessage: "",
    toastStyle: {
      buttons: [
        {
          label: "确认",
          onClick: this.hideDialog.bind(this),
        },
      ],
    },
  };
  hideDialog() {
    this.setState({ toastShow: false, toastMessage: "" });
  }
  showDialog(message) {
    this.setState({ toastShow: true, toastMessage: message });
  }
  sign() {
    const { phone, code } = this.state;
    if (phone === "") this.showDialog("请输入手机号");
    else if (code === "") this.showDialog("请输入验证码");
    else this.checkSMS(phone, code);
  }
  async checkSMS(phone, code) {
    try {
      var res = await API.checkSMS(phone, code);
      if (res.result_code === 0)
        this.getUserBind(phone, DB.OPENID({ type: "GET" }));
      else this.showDialog("验证码有误");
    } catch (e) {
      this.showDialog("验证失败，请重试");
    }
  }
  async getUserBind(phone, openId) {
    try {
      var res = await API.getUserBind(phone, openId);
      if (res.result_code === 0) {
        DB.USERINFO({ type: "SET", value: res.data });
        this.setState({ isOk: true });
      } else {
        this.showDialog("绑定失败");
      }
    } catch (e) {
      this.showDialog("绑定失败, 请重试");
    }
  }
  sendCode() {
    const { phone, isSendCode } = this.state;
    if (!isSendCode) {
      var phoneNumber = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
      if (phoneNumber.test(phone)) this.sendSMS(phone);
      else this.showDialog("输入的手机号有误");
    }
  }
  async sendSMS(phone) {
    this.setState({ isSendCode: true, codeNum: 60 });
    this.timer = setInterval(() => {
      const { codeNum } = this.state;
      var index = codeNum - 1;

      if (index > 0) this.setState({ codeNum: index });
      else {
        this.timer && clearInterval(this.timer);
        this.setState({ isSendCode: false, codeNum: 60 });
      }
    }, 1000);
    try {
      var res = await API.sendSMS(phone);
      if (res.result_code === 0) {
        this.showDialog("验证码发送成功");
      } else {
        this.timer && clearInterval(this.timer);
        this.setState({ isSendCode: false, codeNum: 60 });
        this.showDialog("获取验证码失败");
      }
    } catch (e) {
      this.timer && clearInterval(this.timer);
      this.setState({ isSendCode: false, codeNum: 60 });
      this.showDialog("获取验证码失败, 请重试");
    }
  }
  render() {
    const {
      isOk,
      toastShow,
      toastStyle,
      toastMessage,
      isSendCode,
      codeNum,
    } = this.state;
    if (isOk) return <Redirect to={{ pathname: "/home", state: {} }} />;
    return (
      <div>
        <div
          style={{
            margin: "50px 0 40px 0 ",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={{ width: 120 }} src="../assets/phone.png" alt="phone" />
        </div>
        <Form>
          <FormCell vcode>
            <CellHeader>
              <Label>手机号</Label>
            </CellHeader>
            <CellBody>
              <Input
                type="tel"
                placeholder="请输入手机"
                onChange={(e) => {
                  this.setState({ phone: e.currentTarget.value });
                }}
              />
            </CellBody>
            <CellFooter>
              <Button
                style={{ width: 90, textAlign: "center" }}
                type="vcode"
                onClick={() => this.sendCode()}
              >
                {isSendCode ? codeNum : "获取验证码"}
              </Button>
            </CellFooter>
          </FormCell>
          <FormCell>
            <CellHeader>
              <Label>验证码</Label>
            </CellHeader>
            <CellBody>
              <Input
                type="text"
                placeholder="请输入验证码"
                onChange={(e) => {
                  this.setState({ code: e.currentTarget.value });
                }}
              />
            </CellBody>
          </FormCell>
        </Form>
        <div style={{ height: 50 }} />
        <div style={{ width: "90%", margin: "auto" }}>
          <Button onClick={() => this.sign()}>注册</Button>
          {/* <Button type="default">取消</Button> */}
        </div>
        <Dialog
          type="ios"
          // title={toastMessage}
          buttons={toastStyle.buttons}
          show={toastShow}
        >
          {toastMessage}
        </Dialog>
      </div>
    );
  }
}
export default Login;
