import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Dialog } from "react-weui/build/packages";

import { API, PlatformId } from "../proxy/BaseHttp";
import { DB } from "../proxy/BaseModal";

class Competence extends Component {
  state = {
    isPath: false,
    toastShow: false,
    toastMessage: "",
    toastStyle: {
      buttons: [
        {
          label: "确认",
          onClick: this.hideDialog.bind(this),
        },
      ],
    },
  };

  constructor(props) {
    super(props);
    var url = props.location.search,
      theRequest = {};
    if (url.indexOf("?") !== -1) {
      var strs = url.substr(1).split("&");
      for (var i = 0; i < strs.length; i++)
        theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
    if (process.env.NODE_ENV === "production") {
      if (theRequest.pid) {
        API.setPid(theRequest.pid);
      }

      this.getConfig();
      this.getOpenid(theRequest.code);
    } else if (process.env.NODE_ENV === "development") {
      this.getUserInfo(DB.OPENID({ type: "GET" }));
    }
  }
  hideDialog() {
    this.setState({ toastShow: false, toastMessage: "" });
  }
  showDialog(message) {
    this.setState({ toastShow: true, toastMessage: message });
  }
  async getConfig() {
    try {
      // var res = await API.getSignJsapi("http://wechat-connect.iot-expeed.com");
      var res = await API.getSignJsapi(window.location.href.split("#")[0]);
      window.wx.config({
        beta: true,
        debug: false,
        appId: res.data.appId,
        timestamp: res.data.timestamp,
        nonceStr: res.data.nonceStr,
        signature: res.data.signature,
        jsApiList: [
          "startScanWXDevice",
          "openWXDeviceLib",
          "configWXDeviceWiFi",
          "onScanWXDeviceResult",
          "closeWXDeviceLib",
          "stopScanWXDevice",
        ],
      });

      setTimeout(() => {
        window.wx.checkJsApi({
          jsApiList: [
            "openWXDeviceLib",
            "startScanWXDevice",
            "configWXDeviceWiFi",
            "onScanWXDeviceResult",
            "closeWXDeviceLib",
            "stopScanWXDevice",
          ],
          success: (res) => {},
        });
      }, 1000);
    } catch (error) {
      window.wx.showModal({
        content: "配置接口失败，请退出页面重试",
        showCancel: false,
        success: (res) => {},
      });
    }
  }
  async getOpenid(code) {
    try {
      var res = await API.getOpenId(code, 0);
      if (res.result_code === 0) {
        this.getUserInfo(res.data);
        return res.data;
      } else {
        // this.showDialog(JSON.stringify(res));
        this.showDialog("获取身份信息失败");
        // window.wx.showModal({
        //   content: "获取身份信息失败",
        //   showCancel: false,
        //   success: function(res) {}
        // });
      }
    } catch (e) {
      this.showDialog("获取身份信息失败，请重试");
      // window.wx.showModal({
      //   content: "获取身份信息失败，请重试",
      //   showCancel: false,
      //   success: function(res) {}
      // });
    }
  }
  async getUserInfo(openId) {
    try {
      var res = await API.getUserCheck(openId);
      if (res.result_code === 0) {
        DB.USERINFO({ type: "SET", value: res.data });
        this.setState({ isPath: "/home" });
      } else this.setState({ isPath: "/login" });
    } catch (e) {
      this.setState({ isPath: "/login" });
    }
  }
  render() {
    const { isPath, toastStyle, toastShow, toastMessage } = this.state;

    if (isPath) {
      return <Redirect to={{ pathname: isPath, state: {} }} />;
    }
    return (
      <div>
        <Dialog
          type="ios"
          // title={toastMessage}
          buttons={toastStyle.buttons}
          show={toastShow}
        >
          {toastMessage}
        </Dialog>
      </div>
    );
  }
}
export default Competence;
